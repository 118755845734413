import React, {useEffect, useState} from 'react';
import $ from 'jquery';



type CalcObjType = { [Key: string]: number | string }
export type CalculationDataType = { [key: number]: { [key: string]: string } };
export type CalculationResultType = { [key: string]: string };


const CalculationTable = (props: {
    isEditable: boolean,
    Key: string,
    tableData?: { [Key: number]: CalcObjType }
}) => {

    useEffect(() => {
        const isTable = $(`${props.Key}Table`).length;
        if (isTable === 0) {
            addTable({ Key: props.Key, tableData: props.tableData });
            fillUpTable({ Key: props.Key, tableData: props.tableData });
            setTableState({ Key: props.Key, isEditable: props.isEditable });
            watchEnteredData({ Key: props.Key });
        }

    }, []);




    return (
        <div id={`${props.Key}`} className="cmpny__calculation-table-container"></div>
    );
};



function addTable(params: { Key: string, tableData?: { [Key: number]: CalcObjType } }) {
    const Key = params.Key;
    const container = $(`#${Key}`);
    container.empty();
    container.append(`
            <table id="${Key}Table" class="cmpny__calculation-table">
                <thead>
                <tr>
                    <th colspan="4"></th>
                    <th class="cmpny__table-expenses cmpny__expenses-color" colspan="3">Расход</th>
                    <th class="cmpny__table-income cmpny__income-color" colspan="3">Доход</th>
                    <th colspan="3"></th>
                </tr>
                <tr>
                    <th id="addClmn-${Key}" class="cmpny__calculation-new-clmn" colspan="1"></th>
                    <th colspan="1">Товар</th>
                    <th colspan="1">Кол-во</th>
                    <th colspan="1">Ед.измерения</th>
                    <th class="cmpny__expenses-color" colspan="1">Цена без<br/>ПДВ</th>
                    <th class="cmpny__expenses-color" colspan="1">ПДВ</th>
                    <th class="cmpny__expenses-color" colspan="1">СУММА</th>
                    <th class="cmpny__income-color" colspan="1">Цена без<br/>ПДВ</th>
                    <th class="cmpny__income-color" colspan="1">ПДВ</th>
                    <th class="cmpny__income-color" colspan="1">СУММА</th>
                    <th colspan="1">Д-Р(С/С)</th>
                    <th colspan="1">ЄП</th>
                    <th colspan="1">Военный сбор</th>
                    <th colspan="1">ПРИБЫЛЬ</th>
                </tr>
                </thead>
                <tbody id="${Key}Tbody" class="cmpny__calculation-tbody"></tbody>
            </table>
            
    `);
}

export function fillUpTable(params: { Key: string, tableData?: { [Key: number]: CalcObjType } }) {
    const Key = params.Key;
    const tableData = params.tableData;

    const tableContainer = $(`#${Key}Tbody`);
    tableContainer.empty();

    if (!tableData) {
        tableContainer.append(`
                                <tr id="${Key}-tr-0" class="cmpny__table-tr-${Key}">
                                    <td id="${Key}-z-0" class="cmpny__table-field cmpny__table-del-field-${Key} ${Key}-ctf-z ctf-z"></td>
                                    <td id="${Key}-a-0" class="cmpny__table-field ${Key}-ctf-a ctf-a"></td>
                                    <td id="${Key}-b-0" class="cmpny__table-field ${Key}-ctf-b ctf-b"></td>
                                    <td id="${Key}-c-0" class="cmpny__table-field ${Key}-ctf-c ctf-c"></td>
                                    <td id="${Key}-d-0" class="cmpny__table-field ${Key}-ctf-d ctf-d"></td>
                                    <td id="${Key}-e-0" class="cmpny__table-field ${Key}-ctf-e ctf-e">0.00</td>
                                    <td id="${Key}-f-0" class="cmpny__table-field ${Key}-ctf-f ctf-f">0.00</td>
                                    <td id="${Key}-g-0" class="cmpny__table-field ${Key}-ctf-g ctf-g"></td>
                                    <td id="${Key}-h-0" class="cmpny__table-field ${Key}-ctf-h ctf-h">0.00</td>
                                    <td id="${Key}-i-0" class="cmpny__table-field ${Key}-ctf-i ctf-i">0.00</td>
                                    <td id="${Key}-j-0" class="cmpny__table-field ${Key}-ctf-j ctf-j">0.00</td>
                                    <td id="${Key}-k-0" class="cmpny__table-field ${Key}-ctf-k ctf-k">0.00</td>
                                    <td id="${Key}-w-0" class="cmpny__table-field ${Key}-ctf-w ctf-w">0.00</td>
                                    <td id="${Key}-l-0" class="cmpny__table-field ${Key}-ctf-l ctf-l">0.00</td>
                                </tr>
        `);
    } else {
        const appendedTableData: Array<string> = [];

        for (let i of Object.entries(tableData)) {
            const id = i[0];
            const data = i[1];
            appendedTableData.push(`
                                    <tr id="${Key}-tr-${id}" class="cmpny__table-tr-${Key}">
                                        <td id="${Key}-z-${id}" class="cmpny__table-field cmpny__table-del-field-${Key} ${Key}-ctf-z ctf-z"></td>
                                        <td id="${Key}-a-${id}" class="cmpny__table-field ${Key}-ctf-a ctf-a">${data['a']}</td>
                                        <td id="${Key}-b-${id}" class="cmpny__table-field ${Key}-ctf-b ctf-b">${data['b']}</td>
                                        <td id="${Key}-c-${id}" class="cmpny__table-field ${Key}-ctf-c ctf-c">${data['c']}</td>
                                        <td id="${Key}-d-${id}" class="cmpny__table-field ${Key}-ctf-d ctf-d">${data['d']}</td>
                                        <td id="${Key}-e-${id}" class="cmpny__table-field ${Key}-ctf-e ctf-e">${data['e']}</td>
                                        <td id="${Key}-f-${id}" class="cmpny__table-field ${Key}-ctf-f ctf-f">${data['f']}</td>
                                        <td id="${Key}-g-${id}" class="cmpny__table-field ${Key}-ctf-g ctf-g">${data['g']}</td>
                                        <td id="${Key}-h-${id}" class="cmpny__table-field ${Key}-ctf-h ctf-h">${data['h']}</td>
                                        <td id="${Key}-i-${id}" class="cmpny__table-field ${Key}-ctf-i ctf-i">${data['i']}</td>
                                        <td id="${Key}-j-${id}" class="cmpny__table-field ${Key}-ctf-j ctf-j">${data['j']}</td>
                                        <td id="${Key}-k-${id}" class="cmpny__table-field ${Key}-ctf-k ctf-k">${data['k']}</td>
                                        <td id="${Key}-w-${id}" class="cmpny__table-field ${Key}-ctf-w ctf-w">${data['w'] ? data['w'] : 0}</td>
                                        <td id="${Key}-l-${id}" class="cmpny__table-field ${Key}-ctf-l ctf-l">${data['l']}</td>
                                    </tr>
            `);
        }
        tableContainer.append(appendedTableData.join(''));

    }
}

export function setTableState(params: { Key: string, isEditable: boolean }) {
    const Key = params.Key;
    const isEditable = params.isEditable;


    const newClmnBtnContainer = $(`#addClmn-${Key}`);
    const delClmnBtnContainer = $(`.cmpny__table-del-field-${Key}`);

    if (isEditable) {
        newClmnBtnContainer.empty();
        newClmnBtnContainer.append(`<button class="cmpny__table-addFiled-btn cmpny__table-addFiled-btn-${Key}">&#43;</button>`);

        delClmnBtnContainer.empty();
        delClmnBtnContainer.append(`<button class="cmpny__table-delFiled-btn cmpny__table-delFiled-btn-${Key}">&#215;</button>`)

        $(document).off('click', `.cmpny__table-addFiled-btn-${Key}`);
        $(document).on('click', `.cmpny__table-addFiled-btn-${Key}`, function () {
            handleAddField({ Key: Key })
        });
        $(document).off('click', `.cmpny__table-delFiled-btn-${Key}`);
        $(document).on('click', `.cmpny__table-delFiled-btn-${Key}`, function (e) {
            const elId = `${$(e.target).parent()[0].id}`.split('-').pop();
            handleDeleteField({ elId: elId, Key: Key });
        });

        const contenteditableId = [ `.${Key}-ctf-a`, `.${Key}-ctf-b`, `.${Key}-ctf-c`,
            `.${Key}-ctf-d`, `.${Key}-ctf-g`, ];

        contenteditableId.map((el) => {
            $(el).attr('contenteditable', 'true').addClass('cmpny__field-editable');
        })


    } else {
        newClmnBtnContainer.empty();
        delClmnBtnContainer.empty();
        $(document).off('click', `.cmpny__table-addFiled-btn-${Key}`);
        $(document).off('click', `.cmpny__table-delFiled-btn-${Key}`);

        const contenteditableId = [ `.${Key}-ctf-a`, `.${Key}-ctf-b`, `.${Key}-ctf-c`,
            `.${Key}-ctf-d`, `.${Key}-ctf-g`, ];

        contenteditableId.map((el) => {
            $(el).attr('contenteditable', 'false').removeClass('cmpny__field-editable');
        });
    }

    calculateTotalSum({ Key: Key });

}

function handleDeleteField(params: { elId: string | undefined, Key: string }) {
    const elId = params.elId;
    const Key = params.Key;

    if (elId) {
        $(`#${Key}-tr-${elId}`).remove();
        calculateTotalSum({ Key: Key });
    }
}

function handleAddField(params: { Key: string }) {
    const Key = params.Key;

    const lastItem = $(`.cmpny__table-tr-${Key}`).last()[0];

    let id = 0;

    try {
        //@ts-ignore
        id = parseInt(lastItem.id.split('-').pop()) + 1;
    } catch (err) {
        id = 0;
    }

    $(`#${Key}Tbody`).append(`
        <tr id="${Key}-tr-${id}" class="cmpny__table-tr-${Key}">
            <td id="${Key}-z-${id}" class="cmpny__table-field cmpny__table-del-field-${Key} ${Key}-ctf-z ctf-z">
                <button class="cmpny__table-delFiled-btn cmpny__table-delFiled-btn-${Key}">&#215;</button>
            </td>
            <td id="${Key}-a-${id}" contenteditable="true" class="cmpny__table-field cmpny__field-editable ${Key}-ctf-a ctf-a"></td>
            <td id="${Key}-b-${id}" contenteditable="true" class="cmpny__table-field cmpny__field-editable ${Key}-ctf-b ctf-b"></td>
            <td id="${Key}-c-${id}" contenteditable="true" class="cmpny__table-field cmpny__field-editable ${Key}-ctf-c ctf-c"></td>
            <td id="${Key}-d-${id}" contenteditable="true" class="cmpny__table-field cmpny__field-editable ${Key}-ctf-d ctf-d"></td>
            <td id="${Key}-e-${id}" class="cmpny__table-field ${Key}-ctf-e ctf-e">0.00</td>
            <td id="${Key}-f-${id}" class="cmpny__table-field ${Key}-ctf-f ctf-f">0.00</td>
            <td id="${Key}-g-${id}" contenteditable="true" class="cmpny__table-field cmpny__field-editable ${Key}-ctf-g ctf-g"></td>
            <td id="${Key}-h-${id}" class="cmpny__table-field ${Key}-ctf-h ctf-h">0.00</td>
            <td id="${Key}-i-${id}" class="cmpny__table-field ${Key}-ctf-i ctf-i">0.00</td>
            <td id="${Key}-j-${id}" class="cmpny__table-field ${Key}-ctf-j ctf-j">0.00</td>
            <td id="${Key}-k-${id}" class="cmpny__table-field ${Key}-ctf-k ctf-k">0.00</td>
            <td id="${Key}-w-${id}" class="cmpny__table-field ${Key}-ctf-w ctf-w">0.00</td>
            <td id="${Key}-l-${id}" class="cmpny__table-field ${Key}-ctf-l ctf-l">0.00</td>
        </tr>
    `);
}

function watchEnteredData(params: { Key: string }) {
    const Key = params.Key;
    $(document).off('input', `.${Key}-ctf-b`);
    $(document).off('input', `.${Key}-ctf-d`);
    $(document).off('input', `.${Key}-ctf-g`);

    $(document).on('input', `.${Key}-ctf-b`, function (e) {
        const id = e.target.id.split('-')[2];
        calculateTableLine({ Key: Key, id: id })
    });
    $(document).on('input', `.${Key}-ctf-d`, function (e) {
        const id = e.target.id.split('-')[2];
        calculateTableLine({ Key: Key, id: id })
    });
    $(document).on('input', `.${Key}-ctf-g`, function (e) {
        const id = e.target.id.split('-')[2];
        calculateTableLine({ Key: Key, id: id })
    });
}

function calculateTableLine(param: { Key: string, id: string }) {
    const Key = param.Key;
    const id = param.id;

    let bb = 0;
    let dd = 0;
    let gg = 0;

    try {
        bb = parseFloat($(`#${Key}-b-${id}`).text().replaceAll(",", "."));
        dd = parseFloat($(`#${Key}-d-${id}`).text().replaceAll(",", "."));
        gg = parseFloat($(`#${Key}-g-${id}`).text().replaceAll(",", "."));
        if (isNaN(bb)){
            bb = 0;
        }
        if (isNaN(dd)){
            dd = 0;
        }
        if (isNaN(gg)) {
            gg = 0;
        }
    } catch (error) {
        console.log(error);
    }
    dd = dd * bb;
    gg = gg * bb;

    const ee = dd * 0.20;
    const ff = dd + ee;
    const hh = gg * 0.20;
    const ii = gg + hh;
    const jj = gg - dd;
    const kk = gg * 0.03;
    const ww = gg * 0.01;
    const ll = jj - kk - ww;

    $(`#${Key}-e-${id}`).text(ee.toFixed(2));
    $(`#${Key}-f-${id}`).text(ff.toFixed(2));
    $(`#${Key}-h-${id}`).text(hh.toFixed(2));
    $(`#${Key}-i-${id}`).text(ii.toFixed(2));
    $(`#${Key}-j-${id}`).text(jj.toFixed(2));
    $(`#${Key}-k-${id}`).text(kk.toFixed(2));
    $(`#${Key}-w-${id}`).text(ww.toFixed(2));
    $(`#${Key}-l-${id}`).text(ll.toFixed(2));

    calculateTotalSum({ Key: Key });
}

function calculateTotalSum(params: { Key: string }) {
    const Key = params.Key;

    const allExpensesTblsTax = $(`.${Key}-ctf-f`);
    const allIncomeTblsTax = $(`.${Key}-ctf-i`);
    const allIncomeTbls = $(`.${Key}-ctf-l`);

    let totalExpensesTax = 0;
    for (let i = 0; i < allExpensesTblsTax.length; i++) {
        const res = parseFloat($(allExpensesTblsTax[i]).text());
        totalExpensesTax += res ? res : 0;
    }

    let totalIncomeTax = 0;
    for (let i = 0; i < allIncomeTblsTax.length; i++) {
        const res = parseFloat($(allIncomeTblsTax[i]).text());
        totalIncomeTax += res ? res : 0;
    }

    let totalIncome = 0;
    for (let i = 0; i < allIncomeTbls.length; i++) {
        const res = parseFloat($(allIncomeTbls[i]).text());
        totalIncome += res ? res : 0;
    }

    $(`.cmpny-${Key}__calc-result-expenses-tax`).text(`Сумма расхода с НДС: ${totalExpensesTax.toFixed(2)}`);
    $(`.cmpny-${Key}__calc-result-income-tax`).text(`Сумма дохода с НДС: ${totalIncomeTax.toFixed(2)}`);
    $(`.cmpny-${Key}__calc-result-profit`).text(`Прибыль: ${totalIncome.toFixed(2)}`);
}

export function gatherTableData(params: { Key: string }) {
    const aa = $(`.${params.Key}-ctf-a`);
    const bb = $(`.${params.Key}-ctf-b`);
    const cc = $(`.${params.Key}-ctf-c`);
    const dd = $(`.${params.Key}-ctf-d`);
    const ee = $(`.${params.Key}-ctf-e`);
    const ff = $(`.${params.Key}-ctf-f`);
    const gg = $(`.${params.Key}-ctf-g`);
    const hh = $(`.${params.Key}-ctf-h`);
    const ii = $(`.${params.Key}-ctf-i`);
    const jj = $(`.${params.Key}-ctf-j`);
    const kk = $(`.${params.Key}-ctf-k`);
    const ww = $(`.${params.Key}-ctf-w`);
    const ll = $(`.${params.Key}-ctf-l`);


    const tableDataObj: CalculationDataType = {};
    const resultDataObj: CalculationResultType = {
        'expensesTax': $(`.cmpny-${params.Key}__calc-result-expenses-tax`)[0].innerText,
        'incomeTax': $(`.cmpny-${params.Key}__calc-result-income-tax`)[0].innerText,
        'profit': $(`.cmpny-${params.Key}__calc-result-profit`)[0].innerText,
    };


    for (let i = 0; i < aa.length; i++) {
        if (aa[i].innerText.length !== 0) {
            tableDataObj[i] = {
                'a': aa[i].innerText,
                'b': bb[i].innerText,
                'c': cc[i].innerText,
                'd': dd[i].innerText,
                'e': ee[i].innerText,
                'f': ff[i].innerText,
                'g': gg[i].innerText,
                'h': hh[i].innerText,
                'i': ii[i].innerText,
                'j': jj[i].innerText,
                'k': kk[i].innerText,
                'w': ww[i].innerText,
                'l': ll[i].innerText,
            }
        }
    }

    return {
        tableData: tableDataObj,
        tableResult: resultDataObj,
    }
}



export default CalculationTable;
